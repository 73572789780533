// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

@font-face {
    font-family: "Organetto";
    src: local("Organetto"), url(../assets/fonts/Organetto-UltraBold.ttf) format("truetype");
}

@import "ngx-toastr/toastr";

.toast-container {
  position: fixed;

  .ngx-toastr {
    margin-top: 6px;
  }
}