// Here you can add other styles

//Cursor pointer
.pointer {
  cursor: pointer;
}

// Disable right navbar
.nav.navbar-nav.ml-auto~button.navbar-toggler {
  display: none !important;
}

.menu-icon-dashboard i {
  color: #63C2DE !important;
}

.menu-icon-data-point-options i {
  color: #F86C6B !important;
}

.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }

  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}